import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { catchError } from "@_utils/helpers";

import { Button, Paper, makeStyles } from "@material-ui/core";
import TextfieldInput from "@_components/TextfieldInput/TextfieldInput";
import { userActions } from "@_actions/user.actions";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import styles from "./ChangeName.module.css";

function ChangeName({
  userEmail,
  userName,
  setProfileName,
  setDisplayChangeName,
}) {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.userReducer);
  const useStyles = makeStyles({
    helperText: { fontSize: "12px !important" },

    confirmButton: {
      fontWeight: "bold !important",
      color: "var(--color-theme-blue) !important",
      border: "1px solid var(--color-theme-blue) !important",

      "&:hover": {
        color: "var(--color-theme-white) !important",
        backgroundColor: "var(--color-theme-blue) !important",
        boxShadow: "2px 6px 6px  #0000002",
      },
    },
    cancelButton: {
      fontWeight: "bold !important",
      color: "var(--color-theme-silver) !important",
      border: "1px solid var(--color-theme-silver) !important",
      "&:hover": {
        color: "var(--color-theme-white) !important",
        backgroundColor: "var(--color-theme-silver) !important",
        boxShadow: "2px 6px 6px  #0000002",
      },
    },
  });
  const classes = useStyles();

  const [name, setName] = useState(userName);
  const [nameError, setNameError] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    let username = name && name.trim();
    if (username) {
      setProfileName(username);
      dispatch(userActions.resetName({ name: username }));
    } else {
      setNameError(true);
      setErrorMessage("Please enter a valid name");
    }
  };

  useEffect(() => {
    setProfileName(userName);
  }, [userName, setProfileName]);

  useEffect(() => {
    if (userState.status.USER_RESET_NAME === "success") {
      setSuccessMessage(true);
      setTimeout(() => {
        dispatch(userActions.clearStatusMessage("USER_RESET_NAME"));
        const user = {
          email: userEmail,
          accessToken: userState.user_access_token,
        };
        localStorage.setItem("user", JSON.stringify(user));
        setDisplayChangeName(false);
      }, 2000);
    } else if (catchError(userState.status.USER_RESET_NAME)) {
      setErrorMessage(userState.status.USER_RESET_NAME);
      setNameError(true);
    }
    // eslint-disable-next-line
  }, [userState.status, setDisplayChangeName, dispatch]);

  const handleClickAway = () => {
    setDisplayChangeName(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Paper className={styles.container}>
        <p className={styles.changeNameHeader}>Your Profile</p>
        <p className={styles.email}>{userEmail}</p>
        <TextfieldInput
          label="Your Name"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
            setNameError(false);
          }}
          error={nameError}
          helperText={nameError && errorMessage}
          FormHelperTextProps={{
            className: classes.helperText,
          }}
          required
        />
        <div className={styles.button_container}>
          <Button
            className={classes.cancelButton}
            onClick={() => {
              setDisplayChangeName(false);
            }}
          >
            Cancel
          </Button>
          <Button className={classes.confirmButton} onClick={handleSubmit}>
            Save
          </Button>
        </div>
        {successMessage && (
          <div className={styles.succsse_message}>
            <CheckCircleIcon />
            You have successfully changed your name
          </div>
        )}
      </Paper>
    </ClickAwayListener>
  );
}
export default ChangeName;
