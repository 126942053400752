import Config from "@_configs/api.config";
import {
  authHeader,
  escapeHTML,
  getUserDataFromJwt,
  handleResponse,
} from "@_utils/helpers";
import fetch_helper from "@_utils/fetch_helper";
const fetch = fetch_helper.fetch;

export const caseService = {
  getCase,
  updateCaseOpponentDetails,
  updateCaseLog,
  updateCaseImprint,
  addCaseLog,
  updateCaseFindingImageData,
  updateCaseFindingPrice,
  deleteCaseCallbackRequest,
  deleteCaseLicense,
  updateCaseClaimDetails,
  getCaseChargeDetails,
  updateCaseStateActivate,
  updateCaseStateCancel,
  updateCaseStateReset,
  updateCaseCountry,
  addCaseAdditionalFees,
  uploadLicense,
  updateCaseLicenseState,
  deleteCaseFinding,
  addCaseFinding,
  addCaseFlow,
  addCaseTranslation,
  addCaseSendEmail,
  addEmailToLog,
  updateReminder,
  applyCaseTemplate,
  getCaseActivities,
  addNote,
  deleteNote,
  deleteSecheduledMail,
  getEmailContent,
  getEmailTemplateContentById,
  getEmailTemplateContentByTemplate,
  markEmailUnread,
  toggleAutomation,
  generateInvoice,
  addCaseTag,
  deleteCaseTag,
};

function getCase(id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(Config.API_ROOT + `/cases/${id}`, requestOptions).then(
    handleResponse
  );
}

function updateCaseOpponentDetails(id, data) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT + `/cases/${id}/opponent_contact_details`,
    requestOptions
  ).then(handleResponse);
}

function updateCaseLog(id, logId, data) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT + `/cases/${id}/case_log/${logId}`,
    requestOptions
  ).then(handleResponse);
}

function addCaseLog(id, data) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(Config.API_ROOT + `/cases/${id}/case_log`, requestOptions).then(
    handleResponse
  );
}

function updateCaseImprint(id, data) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT + `/cases/${id}/website_contact_details`,
    requestOptions
  ).then(handleResponse);
}

function updateCaseFindingImageData(id, index, data) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data.original_image),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT + `/cases/${id}/findings/${data.id}/original_image`,
    requestOptions
  ).then(handleResponse);
}

function updateCaseFindingPrice(id, data) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT + `/cases/${id}/findings/${data.id}/price`,
    requestOptions
  ).then(handleResponse);
}

function deleteCaseCallbackRequest(id) {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT + `/cases/${id}/callback_request`,
    requestOptions
  ).then(handleResponse);
}

function updateCaseClaimDetails(id, data) {
  let newData = {
    ...data,
    settlement_offer_date:
      data.settlement_offer_date && data.settlement_offer_date[0] !== "0"
        ? new Date(data.settlement_offer_date).toISOString()
        : "",
  };

  if (
    newData.settlement_offer_percentage === "" ||
    newData.settlement_offer_percentage == null ||
    isNaN(newData.settlement_offer_percentage)
  )
    delete newData.settlement_offer_percentage;

  if (
    newData.settlement_offer_date === "" ||
    newData.settlement_offer_date == null
  )
    delete newData.settlement_offer_date;

  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(newData),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT + `/cases/${id}/claim_details`,
    requestOptions
  ).then(handleResponse);
}

function getCaseChargeDetails(id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT + `/cases/${id}/charge_details`,
    requestOptions
  ).then(handleResponse);
}

function updateCaseStateActivate(id) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT + `/cases/${id}/activate_case`,
    requestOptions
  ).then(handleResponse);
}

function updateCaseStateCancel(id) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT + `/cases/${id}/cancel_case`,
    requestOptions
  ).then(handleResponse);
}

function updateCaseStateReset(id) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT + `/cases/${id}/reset_case`,
    requestOptions
  ).then(handleResponse);
}

function updateCaseCountry(id, data) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify({ country: data.country }),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(Config.API_ROOT + `/cases/${id}/country`, requestOptions).then(
    handleResponse
  );
}

function addCaseAdditionalFees(id, data) {
  let updatedFees;
  if (data) {
    updatedFees = data.map((fee) => ({
      ...fee,
      taxable: fee.taxable ? true : false,
    }));
  }
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(updatedFees || []),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT + `/cases/${id}/claim_details/additional_fees`,
    requestOptions
  ).then(handleResponse);
}

function updateCaseLicenseState(id, data) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT + `/cases/${id}/findings/${data.id}/license/state`,
    requestOptions
  ).then(handleResponse);
}

function deleteCaseLicense(caseId, findingId) {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT + `/cases/${caseId}/findings/${findingId}/license`,
    requestOptions
  ).then(handleResponse);
}

function deleteCaseFinding(id, findingId) {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT + `/cases/${id}/findings/${findingId}`,
    requestOptions
  ).then(handleResponse);
}

function addCaseFinding(id, data) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(Config.API_ROOT + `/cases/${id}/finding`, requestOptions).then(
    handleResponse
  );
}

function uploadLicense(formData, findingId, caseId) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
    },
    body: formData,
  };
  return fetch(
    Config.API_ROOT +
      "/cases/" +
      caseId +
      "/findings/" +
      findingId +
      "/upload_license",
    requestOptions
  ).then(handleResponse);
}

function addCaseFlow(id, flow) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT + `/cases/${id}/email_flows/${flow}`,
    requestOptions
  ).then(handleResponse);
}

function addCaseTranslation(id, translation) {
  const requestOptions = {
    method: "PUT",
    body: translation
      ? JSON.stringify(translation)
      : JSON.stringify({ id: "--", language: "--" }),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT + `/cases/${id}/translation`,
    requestOptions
  ).then(handleResponse);
}

function addCaseSendEmail(id, data, caseActivityId) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  let query = "";
  if (caseActivityId) {
    query = `?case_activity_id=${caseActivityId}`;
  }

  return fetch(
    Config.API_ROOT + `/cases/${id}/outbound_email` + query,
    requestOptions
  ).then(handleResponse);
}

function addEmailToLog(id, data, caseActivityId) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  let query = "";
  if (caseActivityId) {
    query = `?case_activity_id=${caseActivityId}`;
  }

  return fetch(
    Config.API_ROOT + `/cases/${id}/outbound_email_log` + query,
    requestOptions
  ).then(handleResponse);
}

function updateReminder(id, deadline) {
  var requestOptions;
  if (deadline == null)
    requestOptions = {
      method: "DELETE",
      headers: { ...authHeader(), "Content-Type": "application/json" },
    };
  else
    requestOptions = {
      method: "PUT",
      body: JSON.stringify(deadline),
      headers: { ...authHeader(), "Content-Type": "application/json" },
    };

  return fetch(Config.API_ROOT + `/cases/${id}/reminder`, requestOptions).then(
    handleResponse
  );
}

function applyCaseTemplate(case_id, template_id) {
  var requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "Appliction/json" },
  };

  return fetch(
    Config.API_ROOT + `/cases/${case_id}/auto_fill/${template_id}`,
    requestOptions
  ).then(handleResponse);
}

function getCaseActivities(case_id) {
  var requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "Application/json" },
  };

  return fetch(
    Config.API_ROOT + `/cases/${case_id}/activities`,
    requestOptions
  ).then(handleResponse);
}

function addNote(case_id, note) {
  var requestOptions = {
    method: "POST",
    body: JSON.stringify({ text: escapeHTML(note) }),
    headers: { ...authHeader(), "Content-Type": "Application/json" },
  };
  return fetch(Config.API_ROOT + `/cases/${case_id}/note`, requestOptions).then(
    handleResponse
  );
}

function deleteNote(case_id, activity_id) {
  var requestOptions = {
    method: "DELETE",
    headers: { ...authHeader(), "Content-Type": "Application/json" },
  };
  return fetch(
    Config.API_ROOT + `/cases/${case_id}/note/${activity_id}`,
    requestOptions
  ).then(handleResponse);
}

function deleteSecheduledMail(case_id, activity_id) {
  var requestOptions = {
    method: "DELETE",
    headers: { ...authHeader(), "Content-Type": "Application/json" },
  };
  return fetch(
    Config.API_ROOT + `/cases/${case_id}/scheduled_email/${activity_id}`,
    requestOptions
  ).then(handleResponse);
}

function getEmailContent(email_id) {
  var requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "Application/json" },
  };
  return fetch(
    Config.API_ROOT +
      `/customer/${getUserDataFromJwt().customer_id}/email/${email_id}`,
    requestOptions
  )
    .then(handleResponse)
    .catch((error) => {
      console.error(error);
      throw error;
    });
}

function getEmailTemplateContentById(case_id, email_template_id) {
  var requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "Application/json" },
  };
  return fetch(
    Config.API_ROOT +
      `/cases/${case_id}/email_flows/templates/${email_template_id}`,
    requestOptions
  )
    .then(handleResponse)
    .catch((error) => {
      console.error(error);
      throw error;
    });
}

function getEmailTemplateContentByTemplate(case_id, template) {
  var requestOptions = {
    method: "POST",
    body: JSON.stringify(template),
    headers: { ...authHeader(), "Content-Type": "Application/json" },
  };
  return fetch(
    Config.API_ROOT + `/cases/${case_id}/rendered_email`,
    requestOptions
  )
    .then(handleResponse)
    .catch((error) => {
      console.error(error);
      throw error;
    });
}

function markEmailUnread(email_id) {
  var requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "Application/json" },
  };
  return fetch(
    Config.API_ROOT +
      `/customer/${
        getUserDataFromJwt().customer_id
      }/email/${email_id}?unread=true`,
    requestOptions
  )
    .then(handleResponse)
    .catch((error) => {
      console.error(error);
      throw error;
    });
}

function toggleAutomation(caseId, state) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT + `/cases/${caseId}/scheduled_emails?state=${state}`,
    requestOptions
  ).then(handleResponse);
}

function generateInvoice(caseId, address) {
  let url = Config.API_ROOT + `/cases/${caseId}/invoice`;

  const requestOptions = {
    method: "POST",
    body: JSON.stringify(address),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(url, requestOptions)
    .then(handleResponse)
    .catch((error) => {
      console.error(error);
      throw error;
    });
}

function addCaseTag(caseId, tag) {
  let url = Config.API_ROOT + `/cases/${caseId}/case_tag/${tag}`;

  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(url, requestOptions)
    .then(handleResponse)
    .catch((error) => {
      console.error(error);
      throw error;
    });
}

function deleteCaseTag(caseId, tag) {
  let url = Config.API_ROOT + `/cases/${caseId}/case_tag/${tag}`;

  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(url, requestOptions)
    .then(handleResponse)
    .catch((error) => {
      console.error(error);
      throw error;
    });
}
