import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DialogPopup from "@_components/DialogPopup/DialogPopup";
import Button from "@material-ui/core/Button";
import TextfieldInput from "@_components/TextfieldInput/TextfieldInput";
import { caseActions } from "@_actions/case.actions";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "./ChangeOpponentContactDetails.module.css";
import Genders from "@_configs/Genders.config";

function ChangeOpponentContactDetailsPopup(props) {
  const dispatch = useDispatch();
  const caseReducer = useSelector((state) => state.caseReducer);
  const { caseData, from, opponentContactDetails } = props;
  const [newContactInfo, setNewContactInfo] = useState({
    email: "",
    first_name: "",
    last_name: "",
    gender: "",
  });

  useEffect(() => {
    if (from[0]?.name) {
      const fullName = from[0].name;
      const nameParts = fullName.trim().split(" ");
      if (nameParts.length > 1) {
        setNewContactInfo((newContactInfo) => ({
          ...newContactInfo,
          last_name: nameParts.pop(),
          first_name: nameParts.join(" "),
        }));
      } else
        setNewContactInfo((newContactInfo) => ({
          ...newContactInfo,
          last_name: fullName,
        }));
    }
    setNewContactInfo((newContactInfo) => ({
      ...newContactInfo,
      gender: opponentContactDetails?.gender || "NOT_KNOWN",
      email: from[0]?.address || "",
    }));
    // eslint-disable-next-line
  }, [from]);

  useEffect(() => {
    if (caseReducer.status.CASE_UPDATE_OPPONENT_DETAILS === "success") {
      props.close();
      dispatch(caseActions.clearStateMessage("CASE_UPDATE_OPPONENT_DETAILS"));
    }
    // eslint-disable-next-line
  }, [dispatch, caseReducer.status.CASE_UPDATE_OPPONENT_DETAILS]);

  function handleInputChange(e) {
    const { id, value, name } = e.target;
    setNewContactInfo((newContactInfo) => ({
      ...newContactInfo,
      [id || name]: value,
    }));
  }

  function actionClicked() {
    dispatch(
      caseActions.updateCaseOpponentDetails(caseData.id, newContactInfo)
    );
  }

  const closePopup = () => {
    props.close();
  };

  return (
    <DialogPopup
      title="Update contact details?"
      open={props.open}
      onClose={closePopup}
    >
      <div className={styles.content_container}>
        <p>
          {" "}
          The sender of this email is different from the contact saved in the
          website contact details.
          <br />
          Would you like to update the contact information?{" "}
        </p>
        <div>
          <h5 style={{ margin: "5px 0" }}>Email address</h5>
          <div className={styles.details_line}>
            <p className={styles.detail}>{opponentContactDetails?.email}</p>
            <span className={styles.arrow}> {"->"} </span>
            <div className={styles.detail}>
              <p className={styles.detail}> {from[0]?.address}</p>
            </div>
          </div>
          <h5 style={{ margin: "5px 0" }}>First name</h5>
          <div className={styles.details_line}>
            <p
              className={`${styles.detail} ${
                opponentContactDetails?.first_name ? "" : styles.not_set
              }`}
            >
              {" "}
              {opponentContactDetails?.first_name || "not set"}
            </p>
            <span className={styles.arrow}> {"->"} </span>
            <div className={styles.detail}>
              <TextfieldInput
                placeholder="First name"
                className={styles.text_input}
                onChange={handleInputChange}
                name="first_name"
                value={newContactInfo?.first_name}
                required
                inputProps={{
                  tabindex: 2,
                }}
                size="small"
              />
            </div>
          </div>
          <h5 style={{ margin: "5px 0" }}>Last name</h5>
          <div className={styles.details_line}>
            <p
              className={`${styles.detail} ${
                opponentContactDetails?.last_name ? "" : styles.not_set
              }`}
            >
              {" "}
              {opponentContactDetails?.last_name || "not set"}
            </p>
            <span className={styles.arrow}> {"->"} </span>
            <div className={styles.detail}>
              <TextfieldInput
                placeholder="Last name"
                className={styles.text_input}
                onChange={handleInputChange}
                name="last_name"
                value={newContactInfo.last_name}
                required
                inputProps={{
                  tabindex: 3,
                }}
                size="small"
              />
            </div>
          </div>
          <h5 style={{ margin: "5px 0" }}>Gender</h5>
          <div className={styles.details_line}>
            <p
              className={`${styles.detail} ${
                opponentContactDetails?.gender ? "" : styles.not_set
              }`}
            >
              {" "}
              {Genders.find(
                (gender) => gender.id === opponentContactDetails?.gender
              )?.label || "not set"}
            </p>
            <span className={styles.arrow}> {"->"} </span>
            <div className={styles.gender_select}>
              <TextfieldInput
                select
                id="gender"
                name="gender"
                className={styles.text_input}
                value={newContactInfo.gender}
                onChange={handleInputChange}
                size="small"
              >
                {Genders.map((gender) => (
                  <MenuItem key={gender.id} value={gender.id}>
                    {`${gender.label} `}
                  </MenuItem>
                ))}
              </TextfieldInput>
            </div>
          </div>
        </div>
      </div>

      <div className="action_container">
        <>
          <Button className="btn_cancel" onClick={closePopup}>
            Skip
          </Button>
          <Button className="btn_confirm" onClick={actionClicked}>
            Update
          </Button>
        </>
      </div>
    </DialogPopup>
  );
}

export default ChangeOpponentContactDetailsPopup;
