import React, { useState } from "react";
import DialogPopup from "@_components/DialogPopup/DialogPopup";
import Button from "@material-ui/core/Button";
import styles from "./AddCaseTagPopup.module.css";
import { useEffect } from "react";
import { Alert, Autocomplete } from "@material-ui/lab";
import { Snackbar, TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { caseActions } from "@_actions/case.actions";
import { customerActions } from "@_actions/customer.actions";
import { catchError } from "@_utils/helpers";

function AddCaseTagPopup(props) {
  const dispatch = useDispatch();
  const customerReducer = useSelector((state) => state.customerReducer);
  const caseReducer = useSelector((state) => state.caseReducer);
  const caseData = props.caseData;
  const tags = props.tags;
  const [newTag, setNewTag] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [ErrorSnackbarMsg, setErrorSnackbarMsg] = useState("");

  useEffect(() => {
    dispatch(customerActions.getCaseTags());
  }, [dispatch]);

  useEffect(() => {
    if (caseReducer.status.CASE_ADD_TAG === "success") {
      dispatch(caseActions.clearStateMessage("CASE_ADD_TAG"));
      dispatch(
        caseActions.getCaseWithChargeDetails(caseData.id, caseData.overview)
      );
      dispatch(customerActions.getCaseTags());
      onClose();
    } else if (catchError(caseReducer.status.CASE_ADD_TAG)) {
      setOpenErrorSnackbar(true);
      setErrorSnackbarMsg(caseReducer.status.CASE_ADD_TAG);
      dispatch(caseActions.clearStateMessage("CASE_ADD_TAG"));
    }
    // eslint-disable-next-line
  }, [
    caseReducer.status.CASE_ADD_TAG,
    dispatch,
    caseData.id,
    caseData.overview,
  ]);

  const onClose = () => {
    setNewTag("");
    props.close();
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
    setOpenErrorSnackbar(false);
    setErrorSnackbarMsg("");
    setSnackbarMsg("");
  };

  let filteredTags = customerReducer.case_tags.filter(
    (tag) => !tags?.includes(tag)
  );

  const addNewTag = () => {
    if (!newTag) {
      setOpenErrorSnackbar(true);
      setErrorSnackbarMsg("Please add a tag name");
    } else dispatch(caseActions.addCaseTag(caseData.id, newTag));
  };

  return (
    <>
      <DialogPopup open={props.open} onClose={() => props.close()}>
        <div className={styles.add_tag_container}>
          <div className={styles.autocomplete_container}>
            <Autocomplete
              inputValue={newTag}
              onInputChange={(event, newTagInput) => {
                if (
                  !event ||
                  (event.nativeEvent?.type === "input" &&
                    /^[a-zA-Z0-9_\-!+= ]*$/.test(newTagInput))
                )
                  setNewTag(newTagInput);
              }}
              options={filteredTags || []}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </div>
          <Button className="btn_confirm" onClick={addNewTag}>
            {filteredTags?.some((tag) => tag === newTag)
              ? "Add Case Tag"
              : "Create Case Tag"}
          </Button>
        </div>
      </DialogPopup>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          {snackbarMsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          {ErrorSnackbarMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

export default AddCaseTagPopup;
