import { userConstants } from "../@_constants/user.constants";

const initialState = {
  users: "",
  add_user_state: false,
  selected_user: {},
  signup_user: "",
  user_reset_password: "",
  user_access_token: "",
  user_signature: "",
  forgot_password_details: "",
  email_sent: false,
  onboarding_status: {},
  status: {
    USER_GET: "",
    USER_POST: "",
    USER_DELETE: "",
    USER_PUT: "",
    USER_ACTIVATE_PUT: "",
    USER_DEACTIVATE_PUT: "",
    USER_GET_SIGNUP: "",
    USER_PUT_SIGNUP: "",
    USER_RESET_PASSWORD: "",
    USER_RESET_NAME: "",
    USER_PUT_PASSWORD_LINK: "",
    USER_GET_PASSWORD_LINK: "",
    USER_PUT_NEW_PASSWORD: "",
    USER_GET_SIGNATURE: "",
    USER_PUT_NEW_SIGNATURE: "",
    USER_GET_ONBOARDING_STATUS: "",
  },
};

export function userReducer(state = initialState, action) {
  switch (action.type) {
    // USER_CLEAR_STATUS_MESSAGE
    case userConstants.USER_CLEAR_STATUS_MESSAGE:
      state = {
        ...state,
        status: { ...state.status, [action.stateName]: "" },
      };
      break;

    case userConstants.ADD_USER:
      state = {
        ...state,
        add_user_state: action.data,
      };
      break;

    case userConstants.SELECT_USER:
      state = {
        ...state,
        selected_user: action.data,
      };
      break;

    // USER GET REQUEST
    case userConstants.USER_GET_REQUEST:
      state = {
        ...state,
        status: { ...state.status, USER_GET: "request" },
      };
      break;
    case userConstants.USER_GET_SUCCESS:
      state = {
        ...state,
        users: action.data,
        status: { ...state.status, USER_GET: "success" },
      };
      break;
    case userConstants.USER_GET_FAILURE:
      state = {
        ...state,
        status: { ...state.status, USER_GET: action.error },
      };
      break;

    // USER POST REQUEST
    case userConstants.USER_POST_REQUEST:
      state = {
        ...state,
        status: { ...state.status, USER_POST: "request" },
      };
      break;
    case userConstants.USER_POST_SUCCESS:
      state = {
        ...state,
        add_user_state: false,
        status: { ...state.status, USER_POST: "success" },
      };

      break;
    case userConstants.USER_POST_FAILURE:
      state = {
        ...state,
        status: { ...state.status, USER_POST: action.error },
      };
      break;

    // USER PUT REQUEST
    case userConstants.USER_UPDATE_REQUEST:
      state = {
        ...state,
        status: { ...state.status, USER_PUT: "request" },
      };
      break;
    case userConstants.USER_UPDATE_SUCCESS:
      state = {
        ...state,
        status: { ...state.status, USER_PUT: "success" },
      };

      break;
    case userConstants.USER_UPDATE_FAILURE:
      state = {
        ...state,
        status: { ...state.status, USER_PUT: action.error },
      };
      break;

    // USER DELETE REQUEST
    case userConstants.USER_DELETE_REQUEST:
      state = {
        ...state,
        status: { ...state.status, USER_DELETE: "request" },
      };
      break;
    case userConstants.USER_DELETE_SUCCESS:
      state = {
        ...state,
        selected_user: null,
        status: { ...state.status, USER_DELETE: "success" },
      };

      break;
    case userConstants.USER_DELETE_FAILURE:
      state = {
        ...state,
        status: { ...state.status, USER_DELETE: action.error },
      };
      break;

    // ACTIVATE USER PUT REQUEST
    case userConstants.USER_ACTIVATE_REQUEST:
      state = {
        ...state,
        status: { ...state.status, USER_ACTIVATE_PUT: "request" },
      };
      break;
    case userConstants.USER_ACTIVATE_SUCCESS:
      state = {
        ...state,
        status: { ...state.status, USER_ACTIVATE_PUT: "success" },
      };

      break;
    case userConstants.ACTIVATE_USER_FAILURE:
      state = {
        ...state,
        status: { ...state.status, ACTIVATE_USER_PUT: action.error },
      };
      break;

    // DEACTIVATE USER PUT REQUEST
    case userConstants.USER_DEACTIVATE_REQUEST:
      state = {
        ...state,
        status: { ...state.status, USER_DEACTIVATE_PUT: "request" },
      };
      break;
    case userConstants.USER_DEACTIVATE_SUCCESS:
      state = {
        ...state,
        status: { ...state.status, USER_DEACTIVATE_PUT: "success" },
      };

      break;
    case userConstants.USER_DEACTIVATE_FAILURE:
      state = {
        ...state,
        status: { ...state.status, USER_DEACTIVATE_PUT: action.error },
      };
      break;

    // USER SIGNUP GET REQUEST
    case userConstants.USER_GET_SIGNUP_REQUEST:
      state = {
        ...state,
        status: { ...state.status, USER_GET_SIGNUP: "request" },
      };
      break;
    case userConstants.USER_GET_SIGNUP_SUCCESS:
      state = {
        ...state,
        signup_user: action.data,
        status: { ...state.status, USER_GET_SIGNUP: "success" },
      };
      break;
    case userConstants.USER_GET_SIGNUP_FAILURE:
      state = {
        ...state,
        status: { ...state.status, USER_GET_SIGNUP: action.error },
      };
      break;

    // USER SIGNUP PUT REQUEST
    case userConstants.USER_PUT_SIGNUP_REQUEST:
      state = {
        ...state,
        status: { ...state.status, USER_PUT_SIGNUP: "request" },
      };
      break;
    case userConstants.USER_PUT_SIGNUP_SUCCESS:
      state = {
        ...state,
        status: { ...state.status, USER_PUT_SIGNUP: "success" },
      };

      break;
    case userConstants.USER_PUT_SIGNUP_FAILURE:
      state = {
        ...state,
        status: { ...state.status, USER_PUT_SIGNUP: action.error },
      };
      break;
    case userConstants.USER_RESET_NAME_REQUEST:
      state = {
        ...state,
        status: { ...state.status, USER_RESET_NAME: "request" },
      };
      break;
    case userConstants.USER_RESET_NAME_SUCCESS:
      state = {
        ...state,
        user_access_token: action.data.accessToken,
        status: { ...state.status, USER_RESET_NAME: "success" },
      };
      break;

    case userConstants.USER_RESET_NAME_FAILURE:
      state = {
        ...state,
        status: { ...state.status, USER_RESET_NAME: action.error },
      };
      break;

    case userConstants.USER_RESET_PASSWORD_REQUEST:
      state = {
        ...state,
        status: { ...state.status, USER_RESET_PASSWORD: "request" },
      };
      break;

    // user change name
    case userConstants.USER_RESET_PASSWORD_SUCCESS:
      state = {
        ...state,
        user_reset_password: action.data,
        status: { ...state.status, USER_RESET_PASSWORD: "success" },
      };
      break;

    // user change name
    case userConstants.USER_RESET_PASSWORD_FAILURE:
      state = {
        ...state,
        status: { ...state.status, USER_RESET_PASSWORD: action.error },
      };
      break;

    // FORGOT PASSWORD

    // send password change link
    case userConstants.USER_PUT_PASSWORD_LINK_REQUEST:
      state = {
        ...state,
        status: { ...state.status, USER_PUT_PASSWORD_LINK: "request" },
      };
      break;

    case userConstants.USER_PUT_PASSWORD_LINK_SUCCESS:
      state = {
        ...state,
        forgot_password_details: action.data,
        email_sent: true,
        status: { ...state.status, USER_PUT_PASSWORD_LINK: "success" },
      };
      break;

    case userConstants.USER_PUT_PASSWORD_LINK_FAILURE:
      state = {
        ...state,
        status: { ...state.status, USER_PUT_PASSWORD_LINK: action.error },
      };
      break;

    // get password change link
    case userConstants.USER_GET_PASSWORD_LINK_REQUEST:
      state = {
        ...state,
        status: { ...state.status, USER_GET_PASSWORD_LINK: "request" },
      };
      break;

    case userConstants.USER_GET_PASSWORD_LINK_SUCCESS:
      state = {
        ...state,
        forgot_password_details: action.data,
        status: { ...state.status, USER_GET_PASSWORD_LINK: "success" },
      };
      break;
    case userConstants.USER_GET_PASSWORD_LINK_FAILURE:
      state = {
        ...state,
        status: { ...state.status, USER_GET_PASSWORD_LINK: action.error },
      };
      break;

    // get user signature
    case userConstants.USER_GET_SIGNATURE_FAILURE:
      state = {
        ...state,
        status: { ...state.status, USER_GET_SIGNATURE: action.error },
      };
      break;

    case userConstants.USER_GET_SIGNATURE_REQUEST:
      state = {
        ...state,
        status: { ...state.status, USER_GET_SIGNATURE: "request" },
      };
      break;

    case userConstants.USER_GET_SIGNATURE_SUCCESS:
      state = {
        ...state,
        user_signature: action.data.signature,
        status: { ...state.status, USER_GET_SIGNATURE: "success" },
      };
      break;

    //put new signature
    case userConstants.USER_PUT_NEW_SIGNATURE_FAILURE:
      state = {
        ...state,
        status: { ...state.status, USER_PUT_NEW_SIGNATURE: action.error },
      };
      break;

    case userConstants.USER_PUT_NEW_SIGNATURE_REQUEST:
      state = {
        ...state,
        status: { ...state.status, USER_PUT_NEW_SIGNATURE: "request" },
      };
      break;

    case userConstants.USER_PUT_NEW_SIGNATURE_SUCCESS:
      state = {
        ...state,
        user_signature: action.data,
        status: { ...state.status, USER_PUT_NEW_SIGNATURE: "success" },
      };
      break;

    // put new password
    case userConstants.USER_PUT_NEW_PASSWORD_REQUEST:
      state = {
        ...state,
        status: { ...state.status, USER_PUT_NEW_PASSWORD: "request" },
      };
      break;

    case userConstants.USER_PUT_NEW_PASSWORD_SUCCESS:
      state = {
        ...state,
        USER_PUT_NEW_PASSWORD: action.data,
        status: { ...state.status, USER_PUT_NEW_PASSWORD: "success" },
      };
      break;

    case userConstants.USER_PUT_NEW_PASSWORD_FAILURE:
      state = {
        ...state,
        status: { ...state.status, USER_PUT_NEW_PASSWORD: action.error },
      };
      break;

    // get stonly onboarding status
    case userConstants.USER_GET_ONBOARDING_STATUS_REQUEST:
      state = {
        ...state,
        status: { ...state.status, USER_GET_ONBOARDING_STATUS: "request" },
      };
      break;

    case userConstants.USER_GET_ONBOARDING_STATUS_SUCCESS:
      state = {
        ...state,
        onboarding_status: action.data,
        status: { ...state.status, USER_GET_ONBOARDING_STATUS: "success" },
      };
      break;

    case userConstants.USER_GET_ONBOARDING_STATUS_FAILURE:
      state = {
        ...state,
        status: { ...state.status, USER_GET_ONBOARDING_STATUS: action.error },
      };
      break;

    default:
      break;
  }
  return state;
}
