import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./AddressPopup.module.css";
import Button from "@material-ui/core/Button";
import {
  FormControl,
  FormLabel,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import DialogPopup from "@_components/DialogPopup/DialogPopup";
import { caseActions } from "@_actions/case.actions";

function AddressPopup(props) {
  const dispatch = useDispatch();

  const caseInfo = props.case;
  const contactDetails = caseInfo.opponent_contact_details;
  const caseReducer = useSelector((state) => state.caseReducer);
  const [isLoading, setIsLoading] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [showNameWarning, setShowNameWarning] = useState(false);

  const [billingAddress, setBillingAddressInternal] = useState({
    email: "",
    name: "",
    vat_id: "",
    reference_number: "",
    address_line_1: "",
    address_line_2: "",
    postal_code: "",
    city: "",
    country: caseReducer.case[caseInfo.id]?.country || "",
  });

  function setBillingAddress(value) {
    setShowNameWarning(false);
    setBillingAddressInternal(value);
  }

  useEffect(() => {
    if (caseInfo?.billing_address) {
      setBillingAddress({
        email: caseInfo.billing_address.email || "",
        name: caseInfo.billing_address.name || "",
        vat_id: caseInfo.billing_address.vat_id || "",
        reference_number: caseInfo.billing_address.reference_number || "",
        address_line_1: caseInfo.billing_address.address_line_1 || "",
        address_line_2: caseInfo.billing_address.address_line_2 || "",
        postal_code: caseInfo.billing_address.postal_code || "",
        city: caseInfo.billing_address.city || "",
        country: caseReducer.case[caseInfo.id]?.country || "",
      });
    } else if (contactDetails) {
      setBillingAddress({
        email: contactDetails.email || "",
        name: contactDetails.name || "",
        vat_id: contactDetails.vat_id || "",
        reference_number: contactDetails.reference_number || "",
        address_line_1: contactDetails.address_line_1 || "",
        address_line_2: contactDetails.address_line_2 || "",
        postal_code: contactDetails.postal_code || "",
        city: contactDetails.city || "",
        country: caseReducer.case[caseInfo.id]?.country || "",
      });
    }
  }, [props, caseInfo, caseReducer.case, contactDetails]);

  const generateInvoice = () => {
    setIsClicked(true);
    if (
      billingAddress &&
      ((caseInfo.vat_id_required &&
        billingAddress?.vat_id?.trim().length > 0) ||
        !caseInfo.vat_id_required) &&
      billingAddress.email &&
      billingAddress.email.trim().length > 0 &&
      billingAddress.name &&
      billingAddress.name.trim().length > 0 &&
      billingAddress.address_line_1 &&
      billingAddress.address_line_1.trim().length > 0 &&
      billingAddress.postal_code &&
      billingAddress.postal_code.trim().length > 0 &&
      billingAddress.city &&
      billingAddress.city.trim().length > 0 &&
      billingAddress.country &&
      billingAddress.country.trim().length > 0
    ) {
      dispatch(caseActions.generateInvoice(caseInfo.id, billingAddress));
      setIsClicked(false);
      setIsLoading(true);
    } else {
      return;
    }
  };

  useEffect(() => {
    if (caseReducer.status.CASE_GENERATE_INVOICE !== "request") {
      setIsLoading(false);
    }
  }, [caseReducer.status.CASE_GENERATE_INVOICE]);

  const handleClose = () => {
    setIsLoading(false);
    props.onClose();
  };

  const normalView = (
    <>
      <div className={styles.address_row}>
        <FormControl className={styles.half_form}>
          <FormLabel className={styles.text_label}> Name*</FormLabel>
          <TextField
            variant="outlined"
            required
            size="small"
            value={billingAddress.name}
            type="text"
            onChange={(e) => {
              setBillingAddress({
                ...billingAddress,
                name: e.target.value,
              });
            }}
            error={isClicked && !billingAddress.name}
          ></TextField>
        </FormControl>
        <FormControl className={styles.half_form}>
          <FormLabel className={styles.text_label}>Email*</FormLabel>
          <TextField
            variant="outlined"
            type="email"
            required
            size="small"
            value={billingAddress.email}
            onChange={(e) => {
              setBillingAddress({
                ...billingAddress,
                email: e.target.value,
              });
            }}
            error={isClicked && !billingAddress.email}
          ></TextField>
        </FormControl>
      </div>
      <div className={styles.address_row}>
        <FormControl fullWidth>
          <FormLabel className={styles.text_label}>Address Line 1 *</FormLabel>
          <TextField
            variant="outlined"
            id="street"
            value={billingAddress.address_line_1}
            type="text"
            size="small"
            onChange={(e) => {
              setBillingAddress({
                ...billingAddress,
                address_line_1: e.target.value,
              });
            }}
            fullWidth
            error={isClicked && !billingAddress.address_line_1}
          ></TextField>
        </FormControl>
      </div>
      <div className={styles.address_row}>
        <FormControl fullWidth>
          <FormLabel className={styles.text_label}>Address Line 2</FormLabel>
          <TextField
            id="street"
            variant="outlined"
            value={billingAddress.address_line_2}
            type="text"
            size="small"
            fullWidth
            onChange={(e) => {
              setBillingAddress({
                ...billingAddress,
                address_line_2: e.target.value,
              });
            }}
          ></TextField>
        </FormControl>
      </div>
      <div className={styles.address_row}>
        <div className={styles.half_form}>
          <FormControl fullWidth>
            <FormLabel className={styles.text_label}>City*</FormLabel>
            <TextField
              required
              size="small"
              value={billingAddress.city}
              type="text"
              onChange={(e) => {
                setBillingAddress({
                  ...billingAddress,
                  city: e.target.value,
                });
              }}
              variant="outlined"
              error={isClicked && !billingAddress.city}
            ></TextField>
          </FormControl>
        </div>
        <div className={`${styles.half_form} ${styles.address_container} `}>
          <FormControl style={{ width: "45%" }}>
            <FormLabel className={styles.text_label}>Postal Code*</FormLabel>
            <TextField
              required
              size="small"
              value={billingAddress.postal_code}
              type="text"
              onChange={(e) => {
                setBillingAddress({
                  ...billingAddress,
                  postal_code: e.target.value,
                });
              }}
              variant="outlined"
              error={isClicked && !billingAddress.postal_code}
            ></TextField>
          </FormControl>
          <FormControl style={{ width: "25%" }}>
            <FormLabel>Country*</FormLabel>
            <p className={styles.country_text}> {billingAddress.country}</p>
          </FormControl>
        </div>
      </div>
      <div className={styles.address_row}>
        <FormControl className={styles.half_form}>
          <FormLabel className={styles.text_label}>
            {" "}
            Vat Id {caseInfo.vat_id_required ? " *" : ""}
          </FormLabel>
          <TextField
            value={billingAddress.vat_id}
            size="small"
            onChange={(e) => {
              setBillingAddress({
                ...billingAddress,
                vat_id: e.target.value,
              });
            }}
            variant="outlined"
            error={
              isClicked && !billingAddress.vat_id && caseInfo.vat_id_required
            }
          ></TextField>
        </FormControl>
        <FormControl className={styles.half_form}>
          <FormLabel className={styles.text_label}> Reference Number</FormLabel>
          <TextField
            value={billingAddress.reference_number}
            size="small"
            onChange={(e) => {
              setBillingAddress({
                ...billingAddress,
                reference_number: e.target.value,
              });
            }}
            variant="outlined"
          ></TextField>
        </FormControl>
      </div>
    </>
  );

  const mobileView = (
    <>
      <div className={styles.address_row}>
        <FormControl fullWidth>
          <FormLabel className={styles.text_label}> Name*</FormLabel>
          <TextField
            variant="outlined"
            required
            size="small"
            value={billingAddress.name}
            type="text"
            onChange={(e) => {
              setBillingAddress({
                ...billingAddress,
                name: e.target.value,
              });
            }}
            error={isClicked && !billingAddress.name}
          ></TextField>
        </FormControl>
      </div>
      <div className={styles.address_row}>
        <FormControl fullWidth>
          <FormLabel className={styles.text_label}>Email*</FormLabel>
          <TextField
            variant="outlined"
            type="email"
            required
            size="small"
            value={billingAddress.email}
            onChange={(e) => {
              setBillingAddress({
                ...billingAddress,
                email: e.target.value,
              });
            }}
            error={isClicked && !billingAddress.email}
          ></TextField>
        </FormControl>
      </div>
      <div className={styles.address_row}>
        <FormControl fullWidth>
          <FormLabel className={styles.text_label}>Address Line 1*</FormLabel>
          <TextField
            variant="outlined"
            id="street"
            value={billingAddress.address_line_1}
            type="text"
            size="small"
            onChange={(e) => {
              setBillingAddress({
                ...billingAddress,
                address_line_1: e.target.value,
              });
            }}
            fullWidth
            error={isClicked && !billingAddress.address_line_1}
          ></TextField>
        </FormControl>
      </div>
      <div className={styles.address_row}>
        <FormControl fullWidth>
          <FormLabel className={styles.text_label}>Address Line 2</FormLabel>
          <TextField
            id="street"
            variant="outlined"
            value={billingAddress.address_line_2}
            type="text"
            size="small"
            fullWidth
            onChange={(e) => {
              setBillingAddress({
                ...billingAddress,
                address_line_2: e.target.value,
              });
            }}
          ></TextField>
        </FormControl>
      </div>
      <div className={styles.address_row}>
        <FormControl className={styles.half_form}>
          <FormLabel className={styles.text_label}>City*</FormLabel>
          <TextField
            required
            size="small"
            value={billingAddress.city}
            type="text"
            onChange={(e) => {
              setBillingAddress({
                ...billingAddress,
                city: e.target.value,
              });
            }}
            variant="outlined"
            error={isClicked && !billingAddress.city}
          ></TextField>
        </FormControl>
        <FormControl className={styles.half_form}>
          <FormLabel className={styles.text_label}>Postal Code*</FormLabel>
          <TextField
            required
            size="small"
            value={billingAddress.postal_code}
            type="text"
            onChange={(e) => {
              setBillingAddress({
                ...billingAddress,
                postal_code: e.target.value,
              });
            }}
            variant="outlined"
            error={isClicked && !billingAddress.postal_code}
          ></TextField>
        </FormControl>
      </div>
      <div className={styles.address_row}>
        <FormControl className={styles.half_form}>
          <FormLabel className={styles.text_label}>Country*</FormLabel>
          <p className={styles.country_text}> {billingAddress.country}</p>
        </FormControl>
        <FormControl className={styles.half_form}>
          <FormLabel className={styles.text_label}>
            {" "}
            Vat Id {caseInfo.vat_id_required ? " *" : ""}
          </FormLabel>
          <TextField
            value={billingAddress.vat_id}
            size="small"
            onChange={(e) => {
              setBillingAddress({
                ...billingAddress,
                vat_id: e.target.value,
              });
            }}
            variant="outlined"
            error={
              isClicked && !billingAddress.vat_id && caseInfo.vat_id_required
            }
          ></TextField>
        </FormControl>
      </div>
      <FormControl className={styles.half_form}>
        <FormLabel className={styles.text_label}> Reference Number</FormLabel>
        <TextField
          value={billingAddress.reference_number}
          size="small"
          onChange={(e) => {
            setBillingAddress({
              ...billingAddress,
              reference_number: e.target.value,
            });
          }}
          variant="outlined"
        ></TextField>
      </FormControl>
    </>
  );
  return (
    <DialogPopup
      title="Billing information"
      open={props.open}
      onClose={() => props.onClose()}
    >
      <div className={styles.address_modal}>
        <div className={styles.billing_container}>
          <div className={styles.normal_view}> {normalView} </div>
          <div className={styles.mobile_view}> {mobileView} </div>
          <div>
            <div className={styles.actionsContainer}>
              {isLoading ? (
                <div className={styles.loading}>
                  <CircularProgress color="primary" />
                </div>
              ) : (
                <div className="action_container">
                  <Button className="btn_cancel" onClick={handleClose}>
                    Cancel
                  </Button>

                  <Button
                    onClick={generateInvoice}
                    className={`${"btn_confirm"}`}
                    style={{ width: "165px" }}
                  >
                    Generate invoice
                  </Button>
                  <div className={styles.nameWarningContainer}>
                    {showNameWarning ? "Name warning" : <>&nbsp;</>}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </DialogPopup>
  );
}

export default AddressPopup;
