import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@_components/TextfieldInput/TextfieldInput";
import styles from "./UploadLicense.module.css";

import DialogPopup from "@_components/DialogPopup/DialogPopup";
import { caseActions } from "@_actions/case.actions";
import { useDispatch, useSelector } from "react-redux";
import { casesActions } from "@_actions/cases.actions";

const UploadLicensePopup = (props) => {
  const { findingId, caseId } = props;
  const [file, setFile] = useState();
  const [fileObject, setFileObject] = useState({});
  const [fileName, setFileName] = useState("");
  const [note, setNote] = useState("");
  const dispatch = useDispatch();

  const caseReducer = useSelector((state) => {
    return state.caseReducer;
  });

  useEffect(() => {
    let fileArray = {};
    fileArray[findingId] = { findingId };
    setFileObject(fileArray);
  }, [findingId]);

  const handleFileData = (e) => {
    if (e.target.files.length > 0) {
      setFile(e.target.files[0]);
      setFileName(e.target.files[0]?.name);
    }
  };

  const handleResetFile = () => {
    setFile("");
    setFileName("");
    setNote("");
  };

  const handleUpload = () => {
    let fileArray = fileObject;
    fileArray[findingId] = { file, note, findingId };
    Object.keys(fileArray).forEach((findingId) => {
      let formData = new FormData();
      if (fileObject[findingId].file)
        formData.append("license_file", fileObject[findingId].file);
      if (fileObject[findingId].note)
        formData.append("note", fileObject[findingId].note);
      dispatch(caseActions.UploadLicense(formData, findingId, caseId));
    });
    handleResetFile();
    // now we wait for success of upload licence
  };

  useEffect(() => {
    if (caseReducer && caseReducer.status.CASE_VALIDATE_FINDING === "success") {
      // reset status
      dispatch(caseActions.clearStateMessage("CASE_VALIDATE_FINDING"));
      // update everything else and close
      dispatch(casesActions.getCases());
      dispatch(caseActions.getCase(caseId));
      props.close();
    }
  }, [
    caseId,
    caseReducer,
    caseReducer.status.CASE_VALIDATE_FINDING,
    dispatch,
    props,
  ]);

  const handleNote = (e) => {
    setNote(e.target.value);
  };
  const close = () => {
    props.close();
  };
  return (
    <DialogPopup
      title="Upload License"
      open={props.open}
      onClose={close}
      // disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <div className={styles.upload_container}>
        <div className={styles.text_field}>
          <TextField
            className={styles.text_field_input}
            label="License Name"
            name="license"
            size="small"
            defaultValue="optional"
            value={fileName ? fileName : "optional"}
            disabled
          />
        </div>
        <div>
          <Button
            component="label"
            className={`btn_confirm ${styles.upload_button}`}
          >
            <input
              accept=".pdf, .jpg, .jpeg, .gif, .png, .doc, .docx, .odt"
              className={styles.input}
              id="contained-button-file"
              multiple
              type="file"
              hidden
              onChange={handleFileData}
            />
            upload
          </Button>
        </div>
      </div>
      <TextField
        label="Note"
        multiline
        value={note}
        rows={4}
        defaultValue="optional"
        onChange={handleNote}
      />
      <div>
        <div className="action_container">
          <Button className={` btn_cancel ${styles.cancel}`} onClick={close}>
            Cancel
          </Button>
          <Button
            className={` btn_confirm ${styles.confirm}`}
            onClick={handleUpload}
          >
            Add License
          </Button>
        </div>
      </div>
    </DialogPopup>
  );
};

export default UploadLicensePopup;
