import React, { useEffect, useState } from "react";
import DialogPopup from "@_components/DialogPopup/DialogPopup";
import Button from "@material-ui/core/Button";
import TextfieldInput from "@_components/TextfieldInput/TextfieldInput";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "./SelectPopup.module.css";

function SelectPopup(props) {
  const { selections, select, title } = props;
  const [selection, setSelection] = useState("");

  useEffect(() => {
    if (selections && selections[0]) {
      setSelection(selections[0]?.id);
    }
  }, [selections]);

  const handleInputChange = (event) => {
    setSelection(event.target.value);
  };

  const actionClicked = () => {
    if (selection) {
      select(selection);
      setSelection("");
    }
  };

  const handleClose = () => {
    props.close();
    setSelection("");
  };

  return (
    <DialogPopup
      title={title ? title : ""}
      open={props.open}
      onClose={handleClose}
    >
      <div className={styles.content_container}>
        <TextfieldInput
          select
          id="select"
          name="select"
          value={selection}
          onChange={handleInputChange}
        >
          {selections &&
            selections.map((selection, index) => (
              <MenuItem key={selection.id} value={selection.id}>
                {selection.name}
              </MenuItem>
            ))}
        </TextfieldInput>
      </div>
      <div className="action_container">
        <>
          <Button className="btn_cancel" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            className={`${"btn_confirm"} ${!selection ? "btn_disabled" : ""}`}
            onClick={actionClicked}
            disabled={!selection}
          >
            Confirm
          </Button>
        </>
      </div>
    </DialogPopup>
  );
}

export default SelectPopup;
